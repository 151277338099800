import React, {useState} from 'react';
import style from './About.style';

const VideoModal = () => (
    <div className={style.About__widget}>
            <iframe
                src="https://www.youtube.com/embed/8uPTZQHOv18?autoplay=1&modestbranding=1&controls=0"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
            />
    </div>
);

export default VideoModal;


// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Offer-style__Offer--3ZJa6 {\n  max-width: 400px !important;\n  margin: 40px auto !important; }\n  @media screen and (min-width: 768px) {\n    .Offer-style__Offer--3ZJa6 {\n      display: grid !important;\n      grid-template-columns: 100%;\n      width: 75% !important;\n      max-width: 768px !important; } }\n  @media screen and (min-width: 1200px) {\n    .Offer-style__Offer--3ZJa6 {\n      grid-template-columns: 430px auto;\n      max-width: 1200px !important;\n      grid-column-gap: 80px;\n      width: 100% !important; } }\n  @media screen and (min-width: 1200px) {\n    .Offer-style__Offer__couponBlock--1kFPi {\n      width: 45%;\n      grid-column: 2;\n      grid-row: 5; } }\n  .Offer-style__Offer__box--2xHCm {\n    display: flex;\n    justify-content: space-between;\n    align-items: baseline;\n    width: 230px;\n    margin-right: auto; }\n    @media screen and (min-width: 1200px) {\n      .Offer-style__Offer__box--2xHCm {\n        grid-column: 2;\n        grid-row: 4; } }\n", ""]);
// Exports
exports.locals = {
	"Offer": "Offer-style__Offer--3ZJa6",
	"Offer__couponBlock": "Offer-style__Offer__couponBlock--1kFPi",
	"Offer__box": "Offer-style__Offer__box--2xHCm"
};
module.exports = exports;

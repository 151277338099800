import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
import style from './ModalPopup.style';
import { connect } from 'react-redux';
import {showModalAction} from 'actions/popup';


const modalRoot = document.getElementById('modal');

const ModalPopup = (props) => {
    const {
        showModal,
        children,
        showModalAction,
    } =props;

    if(showModal){
        return ReactDOM.createPortal(
            <div className={classNames('modal-backdrop', style.modalPopup__backdrop)}>
                <div className="modal-dialog modal-dialog-centered" tabIndex="-1" role="dialog">
                    <div className={classNames('modal-content', style.modalPopup)}>
                        <button type="button" onClick={showModalAction} className={classNames('close', style.modalPopup__close_btn)} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className={classNames('modal-body', style.modalPopup__body)}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            ,
            modalRoot
        );
    }
    return null;
};

const mapStateToProps = (state)  => {
    const {
        popup: { showModal },
    } = state;
    return {
        showModal,
    };
};

const mapDispatchToProps = {
    showModalAction,
};


export default connect(mapStateToProps, mapDispatchToProps)(ModalPopup);
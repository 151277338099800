import React from 'react';

const Images = ({ mobile, defaultImg, retina, webMobile, webRetina, className = ''}) => {
    return (
        <picture>
            <source type="image/webp" media="(min-width: 1020px)" srcSet={webRetina}/>
            <source type="image/webp" media="(min-width: 319px)" srcSet={webMobile}/>

            <source srcSet={retina} media="(min-width: 1020px)" />
            <source srcSet={mobile} media="(min-width: 319px)" />
            <img className={className} src={defaultImg} alt='' width='300' height='300' />
        </picture>
    )
};

export default Images;
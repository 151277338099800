// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes Loader-style__spinLoader--Nod_D {\n  from {\n    transform: rotate(0deg); }\n  to {\n    transform: rotate(360deg); } }\n\n.Loader-style__loader--2UYE- {\n  width: 100vw;\n  height: 100vh;\n  background-color: #ffffff;\n  border-radius: 4px;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 55;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center; }\n  .Loader-style__loader__spinner--2gffb {\n    height: 100px;\n    width: 100px;\n    border-radius: 50%;\n    background-color: transparent;\n    border: 3px solid #23CBA7;\n    border-right-color: transparent;\n    animation: Loader-style__spinLoader--Nod_D 2s linear infinite; }\n", ""]);
// Exports
exports.locals = {
	"loader": "Loader-style__loader--2UYE-",
	"loader__spinner": "Loader-style__loader__spinner--2gffb",
	"spinLoader": "Loader-style__spinLoader--Nod_D"
};
module.exports = exports;

export const SPINNER = 'spinner';
export const showSpinnerAction  = (payload) => {
    return {
        type: SPINNER,
        payload,
    };
};

export const MODAL = 'modal';
export const showModalAction  = (payload) => {
    return {
        type: MODAL,
        payload,
    };
};
import { createAsyncAction } from '../common';


export const GET_COUNTRY_DATA = createAsyncAction('getCountryData');
export const getCountryDataAction = (payload) => {
    return {
        type: GET_COUNTRY_DATA.REQUEST,
        payload,
    };
};



import { createReducer } from '../common';
import { SPINNER, MODAL } from 'actions/popup';
const initState = {
    showSpinner: false,
    showModal: false,
};

const showSpinner = (state, { payload }) => {
    const { showSpinner } = state;
    return {
        ...state,
        showSpinner: !showSpinner,
        ...payload,
    };
};

const showModal = (state, { payload }) => {
    const { showModal } = state;
    return {
        ...state,
        showModal: !showModal,
        ...payload,
    };
};

const handlers = {
    [SPINNER]: showSpinner,
    [MODAL]: showModal,
};

export default createReducer(initState, handlers);

import React from 'react';
import style from './BuyBlock.style';
import visa from 'assets/main/visa-icon.png';
import master from 'assets/main/master-icon.png';
import amex from 'assets/main/Amex-icon.svg';
import payPal from 'assets/main/paypal-icon.png';
import Button from '../UI/Button/Button';

const BuyBlock = ({onClick}) => (
    <div className={style.BuyBlock}>
        <Button onClick={onClick} type='home'>Buy Now</Button>
        <ul className={style.BuyBlock__list}>
            <li className={style.BuyBlock__item}>
                <img src={visa} alt='VISA'/>
            </li>

            <li className={style.BuyBlock__item}>
                <img src={master} alt='Master Card'/>
            </li>

            <li className={style.BuyBlock__item}>
                <img src={amex} alt='American Axpress'/>
            </li>

            <li className={style.BuyBlock__item}>
                <img src={payPal} alt='PayPal'/>
            </li>

        </ul>
        <p className={style.BuyBlock__text}>
        {`Monthly service plan is required. Fees start at $4.4 for long term plans and are sold in the GeoZilla app.
        Additional customs fees, tariffs, or local taxes may apply. Please check with your local customs regulations if there is an additional fee for international deliveries.`}
        </p>

    </div>
);


export default BuyBlock;
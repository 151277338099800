// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header-style__header--3JncB {\n  max-width: 1200px;\n  height: 80px;\n  margin: 0 auto;\n  padding: 10px 12px; }\n  .Header-style__header_withNav--3pm1D {\n    max-width: 1200px;\n    height: 80px;\n    margin: 0 auto 60px;\n    padding: 10px 12px;\n    display: flex;\n    flex-direction: column; }\n    .Header-style__header_withNav--3pm1D svg {\n      width: 40px;\n      height: 50px;\n      margin-bottom: 16px; }\n      @media screen and (min-width: 768px) {\n        .Header-style__header_withNav--3pm1D svg {\n          width: 52px;\n          height: 66px; } }\n    @media screen and (min-width: 768px) {\n      .Header-style__header_withNav--3pm1D {\n        margin-bottom: 5px;\n        padding-top: 15px;\n        flex-direction: row;\n        justify-content: space-between; } }\n    @media screen and (min-width: 1500px) {\n      .Header-style__header_withNav--3pm1D {\n        margin-bottom: 50px;\n        padding-top: 40px; } }\n  .Header-style__header--3JncB svg {\n    width: 40px;\n    height: 50px;\n    margin-bottom: 16px; }\n    @media screen and (min-width: 768px) {\n      .Header-style__header--3JncB svg {\n        width: 52px;\n        height: 66px; } }\n", ""]);
// Exports
exports.locals = {
	"header": "Header-style__header--3JncB",
	"header_withNav": "Header-style__header_withNav--3pm1D"
};
module.exports = exports;

export const createReducer = (initialState, handlers) => (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export const createAsyncAction = (type) => ({
    REQUEST: `${type}.REQUEST`,
    SUCCESS: `${type}.SUCCESS`,
    FAILURE: `${type}.FAILURE`,
});

export const createAction = (type) => (payload) => {
    return {
        type,
        payload,
    };
};
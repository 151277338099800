// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Alert-style__Alert--3-hzx {\n  position: fixed !important;\n  top: 5%;\n  z-index: 100; }\n", ""]);
// Exports
exports.locals = {
	"Alert": "Alert-style__Alert--3-hzx"
};
module.exports = exports;

import React from 'react';
import style from './Loader.style';
import {connect} from 'react-redux';

const Loader = ({showSpinner}) => {

    if(showSpinner) {
        return (
            <div className={style.loader}>
                <div className={style.loader__spinner}> </div>
            </div>
        );
    }
    return null;
};

const mapStateToProps = ({popup: {showSpinner}}) => ({showSpinner});

export default connect(mapStateToProps, null)(Loader);
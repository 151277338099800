// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Title-style__Title--3lhji {\n  width: 100%;\n  text-align: left; }\n  @media screen and (min-width: 768px) {\n    .Title-style__Title--3lhji {\n      grid-row: 2; } }\n  @media screen and (min-width: 1200px) {\n    .Title-style__Title--3lhji {\n      grid-row: 1;\n      grid-column: 2; } }\n  .Title-style__Title__container--2PoQB {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: baseline; }\n    @media screen and (min-width: 768px) {\n      .Title-style__Title__container--2PoQB {\n        padding-top: 16px;\n        flex-wrap: nowrap;\n        align-items: end; } }\n  .Title-style__Title__title--1fqcR {\n    font-family: Avenir-Medium;\n    font-size: 24px;\n    line-height: 33px;\n    color: #323232;\n    margin-bottom: 0; }\n    @media screen and (min-width: 1140px) {\n      .Title-style__Title__title--1fqcR {\n        font-size: 32px;\n        line-height: 44px;\n        padding-bottom: 30px; } }\n  .Title-style__Title__stars--3eAah {\n    width: 120px;\n    margin-right: auto;\n    padding: 10px 0;\n    box-sizing: border-box; }\n    @media screen and (min-width: 768px) {\n      .Title-style__Title__stars--3eAah {\n        width: 145px;\n        padding: 0;\n        margin-right: 40px; } }\n  .Title-style__Title__text--qftj7 {\n    font-family: Avenir-Light;\n    font-size: 12px;\n    line-height: 16px;\n    color: #292929;\n    width: 244px;\n    margin-right: auto; }\n    @media screen and (min-width: 768px) {\n      .Title-style__Title__text--qftj7 {\n        width: unset;\n        font-size: 18px;\n        line-height: 25px; } }\n  .Title-style__Title__heartBtn--1wofH {\n    border: none;\n    background-color: transparent; }\n    .Title-style__Title__heartBtn--1wofH:focus {\n      outline: turquoise; }\n", ""]);
// Exports
exports.locals = {
	"Title": "Title-style__Title--3lhji",
	"Title__container": "Title-style__Title__container--2PoQB",
	"Title__title": "Title-style__Title__title--1fqcR",
	"Title__stars": "Title-style__Title__stars--3eAah",
	"Title__text": "Title-style__Title__text--qftj7",
	"Title__heartBtn": "Title-style__Title__heartBtn--1wofH"
};
module.exports = exports;

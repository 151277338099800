import React from 'react';
import style from './Cards.style';
import Card from '../UI/Card/Card';
import { PLANS } from '../../helper/common';
import { connect } from 'react-redux';
import {selectPlanActions} from 'actions/main';



const Cards = ({ plan, selectPlanActions }) => {


    const renderCard = (product) => {


        return (
            <li key={product.name} className={style.Cards__item}>
                <Card type={product.url === plan ? 'main' : null}>
                    <label className={style.Cards__label}>
                        <input type='radio' className={style.Cards__btn} name='product' value={product.url} checked={product.url === plan} onChange={()=>selectPlanActions(product.url)}/>
                        <img className={style.Cards__img} src={product.icon} alt={product.name}/>
                        <p className={style.Cards__name}>{product.name}</p>
                        <p className={style.Cards__heading}>{product.heading}</p>
                        {product.save && <p className={style.Cards__save}>{product.save}</p>}
                        {product.note &&  <p className={style.Cards__note}>{product.note}</p>}
                    </label>
                </Card>
            </li>
        );
    };

    return (
        <ul className={style.Cards}>
            {Object.keys(PLANS).map(i => renderCard(PLANS[i]))}
        </ul>
    );
};

const mapStateToProps = ({main}) => {
    return {
        ...main
    };
};

const mapDispatchToProps = {
    selectPlanActions
};


export default connect(mapStateToProps, mapDispatchToProps)(Cards);
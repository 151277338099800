import React from 'react';
import style from './WhatsInBox.style';
import Button from '../UI/Button/Button';
import img from 'assets/main/default/Unbox.png';
import classNames from 'classnames/bind';


const WhatsInBox = ({onClick}) => (
    <div className={style.WhatsInBox}>
        <div className={classNames('container', style.WhatsInBox__container)} >
            <b className={style.WhatsInBox__title}>What’s in the box:</b>
            <img src={img} alt='Whats in teh box' className={style.WhatsInBox__img}/>
            <div className={style.WhatsInBox__list}>
                <div>
                    <p className={style.WhatsInBox__item}>1) GeoZilla Tracker</p>
                    <p className={style.WhatsInBox__item}>2) 1 Worldwide Sim Card</p>
                    <p className={style.WhatsInBox__item}>3) 1 Rechargeable Battery</p>
                </div>
                <div>
                    <p className={style.WhatsInBox__item}>4) USB Cable</p>
                    <p className={style.WhatsInBox__item}>5) Lanyard</p>
                    <p className={style.WhatsInBox__item}>6) Attachment Pouch - for you dog, belt, bag</p></div>  
            </div>
            <div className={style.WhatsInBox__btn}>
                <Button type='checkout' onClick={onClick}>Buy now</Button>
            </div>
        </div>
    </div>
);

export default WhatsInBox;











     
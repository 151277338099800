import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { composeWithDevTools } from 'redux-devtools-extension';
import allSaga from './sagas';
import allReducers from './reducers';

function* rootSaga(param) {
    yield all([...allSaga].map((saga) => fork(saga, param)));
}
const sagaMiddleware = createSagaMiddleware();

const rootRreducer = combineReducers(allReducers);

let appliedMiddlewares = applyMiddleware(sagaMiddleware);
if (process.env.NODE_ENV === 'development') {
    appliedMiddlewares = composeWithDevTools(appliedMiddlewares);
}

const store = createStore(rootRreducer, appliedMiddlewares);

sagaMiddleware.run(rootSaga);

export default store;
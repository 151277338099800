import {
    createReducer
} from '../common';
import {
    BUY_PLAN, SET_TOTAL_TRACKER, SET_ORDER_ID, SET_SHIPPING_COST, SET_SUBSC_ID, SET_STATIC_TOTAL
} from 'actions/checkout';

const initState = {
    done: false,
    total: null,
    orderId: '',
    shippingCost: 0,
    salesTax: 0,
    subscriptionID: '',
};

const done = (state, {
    payload
}) => {
    return {
        ...state,
        done: !!payload
    };
};

const setTotal = (state, {
    payload
}) => {
    return {
        ...state,
        total: payload,
    };
};

const setShippingCost = (state, {
    payload
}) => {

    const {
        shippingCost,
        salesTax
    } = payload;

    return {
        ...state,
        shippingCost: shippingCost,
        salesTax: salesTax
    };
};

const setId = (state, {
    payload
}) => {
    return {
        ...state,
        orderId: payload,
    };
};

const setSubscriptionId = (state, {
    payload
}) => {
    return {
        ...state,
        subscriptionID: payload,
    };
};

const handlers = {
    [BUY_PLAN.SUCCESS]: done,
    [SET_STATIC_TOTAL]:setTotal,
    [SET_TOTAL_TRACKER.SUCCESS]: setTotal,
    [SET_ORDER_ID]: setId,
    [SET_SUBSC_ID]: setSubscriptionId,
    [SET_SHIPPING_COST.SUCCESS]: setShippingCost,
};

export default createReducer(initState, handlers);
import {createReducer} from '../common';
import {CHANGE_FORM, CHANGE_FORM_PAYPAL, SET_COUNTRY_CODE, SET_COUNTRY} from 'actions/form';

const initState = {
    name: {
        value: '',
        touched: false,
        isValid: null
    },
    street: {
        value: '',
        touched: false,
        isValid: null
    },

    apt: {
        value: '',
        touched: false,
        isValid: null
    },

    zip: {
        value: '',
        touched: false,
        isValid: null
    },

    state: {
        value: '',
        touched: false,
        isValid: null
    },

    textArea: {
        value: '',
        touched: false,
        isValid: true
    },

    email: {
        value: '',
        isValid: null
    },

    confirmEmail: {
        value: '',
        isValid: null
    },

    phone: {
        value: '',
        touched: false,
        isValid: null
    },

    city: {
        value: '',
        touched: false,
        isValid: null
    },

    country: {
        value: 'US',
        isValid: true
    },
    countryCode: {
        value: '',
        isValid: true
    },

};

const changeForm = (state, {payload}) => {
    const {
        name,
        value,
        isValid,
        touched,
    } = payload;

    if (touched) {

        return {
            ...state,
            [name]: {
                ...state[name],
                touched,
                value,
            }
        };
    }

    return {
        ...state,
        [name]: {
            ...state[name],
            touched,
            isValid,
        },
    };
};

const changeFormPaypal = (state, { payload }) => {
    const formValues = Object.entries(payload);
    const updateState = formValues.reduce((acc, [key, value]) =>{
        return {
            ...acc,
            [key]: {
                ...acc[key],
                value,
                isValid: true,
            }
        };
    }, state);
    return updateState;
};

const changeCountryCode = (state, { payload }) => {
    return {
        ...state,
        countryCode: {
            value: payload,
            isValid: true,
        }
    };
};

const changeCountry = (state, { payload }) => {
    return {
        ...state,
        country: {
            value: payload,
            isValid: true,
        },
    };
};


const handlers = {
    [CHANGE_FORM]: changeForm,
    [CHANGE_FORM_PAYPAL]: changeFormPaypal,
    [SET_COUNTRY_CODE]: changeCountryCode,
    [SET_COUNTRY]: changeCountry,
};

export default createReducer(initState, handlers);

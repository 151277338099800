import React, {Suspense} from 'react';
import style from './Offer.style';
import {connect} from 'react-redux';
import classNames from 'classnames/bind';
import Title from '../../components/Title/Title';
import Slider from '../Slider/Slider';
import Cards from '../../components/Cards/Cards';
import Counter from '../../components/Counter/Counter';
import Coupon from '../../components/Coupon/Coupon';
import Guarantee from '../../components/Guarantee/Guarantee';
import BuyBlock from '../../components/BuyBlock/BuyBlock';
const Price = React.lazy(() => import('../Price/Price'));
const Info = React.lazy(() => import('../UI/Info/Info'));



const Offer = ({ plan, count, allInPrice, trackerPrice, trackerInitPrice, allInInitPrice, onClick}) => {
    
    let currentPrice;
    let oldPrice;

    switch (plan) {
    case 'all-in-bundle':
        currentPrice = allInPrice;
        oldPrice = allInInitPrice;
        break;
    case 'device-only':
        currentPrice = trackerPrice;
        oldPrice = trackerInitPrice;
        break;
    default:
        currentPrice = allInPrice;
        oldPrice = allInInitPrice;
    }


    return (
        <section className={classNames('container', style.Offer)}>
            <Title />
            <Slider />
            <Cards />
            
            <div className={style.Offer__box}>
                {plan !== 'all-in-bundle' && <Counter />}
                <Suspense fallback={<div></div>}>
                    {trackerPrice && allInPrice && (
                        <Price price={currentPrice} oldPrice={oldPrice} count={count}/>
                    )}
                </Suspense>
               
            </div>
            {plan !== 'all-in-bundle' && (
                <Suspense fallback={<div></div>}>
                    <div className={style.Offer__couponBlock}>
                        <Info />
                        <Coupon />
                    </div>
                </Suspense>
                
            )}
            <BuyBlock onClick={onClick}/>
            <Guarantee />
           
        </section>
    );
};

const mapStateToProps = ({main}) => {
    return {
        ...main
    };
};


export default connect(mapStateToProps)(Offer);
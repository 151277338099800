// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button-style__button--1kUx2 {\n  display: block;\n  width: 296px;\n  height: 48px;\n  background-color: #00BFA5;\n  border-radius: 100px;\n  border: none;\n  color: #fff;\n  font-family: Avenir-Medium;\n  font-size: 16px;\n  line-height: 19px;\n  text-transform: uppercase;\n  outline: none; }\n  @media screen and (max-width: 374px) {\n    .Button-style__button--1kUx2 {\n      width: 230px; } }\n  @media screen and (min-width: 768px) {\n    .Button-style__button--1kUx2 {\n      font-size: 18px;\n      line-height: 21px; } }\n  .Button-style__button--1kUx2:hover {\n    background-color: #22CAA9; }\n  .Button-style__button--1kUx2:active {\n    background-color: #00A68F; }\n  .Button-style__button--1kUx2:focus {\n    outline: none; }\n  .Button-style__button--1kUx2:disabled {\n    background-color: #9C9C9C; }\n\n.Button-style__checkout--aZtQF {\n  width: 100%;\n  max-width: 360px;\n  text-transform: none;\n  margin: 24px auto; }\n  @media screen and (min-width: 768px) {\n    .Button-style__checkout--aZtQF {\n      width: 399px;\n      margin: 16px auto 0; } }\n\n.Button-style__cookies--1twe_ {\n  width: 178px;\n  margin: 6px auto;\n  text-transform: none;\n  height: 36px;\n  font-size: 14px; }\n\n.Button-style__home--2VzD3 {\n  width: 100%;\n  text-transform: none;\n  margin: 24px auto; }\n  @media screen and (min-width: 768px) {\n    .Button-style__home--2VzD3 {\n      width: 280px;\n      margin: 0 0 0 auto;\n      order: 2; } }\n\n.Button-style__signin--2C-no {\n  text-transform: none;\n  margin: 10px auto; }\n\n.Button-style__small--1BXdu {\n  width: 200px; }\n\n.Button-style__content--sLM06 {\n  margin-top: 16px; }\n", ""]);
// Exports
exports.locals = {
	"button": "Button-style__button--1kUx2",
	"checkout": "Button-style__checkout--aZtQF",
	"cookies": "Button-style__cookies--1twe_",
	"home": "Button-style__home--2VzD3",
	"signin": "Button-style__signin--2C-no",
	"small": "Button-style__small--1BXdu",
	"content": "Button-style__content--sLM06"
};
module.exports = exports;

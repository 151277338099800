import { put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { GET_BT_TOKEN, APPLY_COUPON, SET_DISCOUNTED_PRICE, GET_SHIPPING_INFO, GET_BUNDLE_DATA, GET_TRACKER_DATA } from '../actions/main';
import { SPINNER } from 'actions/popup';
import API from 'api';

function* getBtToken() {

    const {
        main: {
            captchaTokenBrainTree
        }
    } = yield select();

    try {
        const res = yield call(API.getBtToken, {captchaTokenBrainTree});
        yield put({ type: GET_BT_TOKEN.SUCCESS, payload: res });
    } catch (error) {
        yield put({ type: GET_BT_TOKEN.FAILURE, payload: {type: 'Braintree', show: true, body: 'Something went wrong'} });
    }
}

function* getBtTokenFlow() {
    yield takeEvery(GET_BT_TOKEN.REQUEST, getBtToken);
}

function* getBundleData() {
    try {
        const res = yield call(API.getProductInfo, { 'sku': '12-months-all-inclusive-package', 'type': 'ai_package' });
        yield put({ type: GET_BUNDLE_DATA.SUCCESS, payload: res.price });
    } catch (error) {
        yield put({ type: GET_BUNDLE_DATA.FAILURE, payload: {type: 'Pricing', show: true, body: 'Something went wrong'} });
    }
}

function* getBundleDataFlow() {
    yield takeEvery(GET_BUNDLE_DATA.REQUEST, getBundleData);
}

function* getTrackerData() {
    try {
        const res = yield call(API.getProductInfo, { 'sku': 'v2', 'type': 'trackimo_device' });
        yield put({ type: GET_TRACKER_DATA.SUCCESS, payload: res.price });
    } catch (error) {
        yield put({ type: GET_TRACKER_DATA.FAILURE, payload: {type: 'Pricing', show: true, body: 'Something went wrong'} });
    }
}

function* getTrackerDataFlow() {
    yield takeEvery(GET_TRACKER_DATA.REQUEST, getTrackerData);
}

function* getShippingInfo() {
    try {
        const res = yield call(API.getShippingInfo);
        const regionData = res.find(item => item.country_iso2 === 'US');
        const countriesList = res.map(item => item.country_iso2);


        yield put({ type: GET_SHIPPING_INFO.SUCCESS, payload: { countriesList: countriesList, shippingCost:  regionData}});
    } catch (error) {
        yield put({ type: GET_SHIPPING_INFO.FAILURE, payload: {type: 'Tracker', show: true, body: 'Something went wrong'} });
    }
}

function* getShippingInfoFlow() {
    yield takeEvery(GET_SHIPPING_INFO.REQUEST, getShippingInfo);
}


function* applyCoupon() {
    const {
        main: {
            coupon,
            count
        }
    } = yield select();

    yield put({ type: SPINNER });


    try {
        const res = yield call(API.calculateTracker, {
            count,
            coupon,
            paymentType: 'braintree'
        });

        yield put({ type: APPLY_COUPON.SUCCESS, payload: res });
        yield put({ type: SET_DISCOUNTED_PRICE, payload: res.pricing.devices_total });

    } catch (error) {
        yield put({ type: APPLY_COUPON.FAILURE, payload: {type: 'Coupon', show: true, body: 'Invalid Coupon Code'} });
    }

    yield put({ type: SPINNER });
}

function* applyCouponFlow() {
    yield takeEvery(APPLY_COUPON.REQUEST, applyCoupon);
}


export default [
    getBtTokenFlow,
    applyCouponFlow,
    getShippingInfoFlow,
    getBundleDataFlow,
    getTrackerDataFlow
];
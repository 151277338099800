import { createReducer } from '../common';
import { SELECT_PLAN, GET_BT_TOKEN, SET_COUPON, SET_NONCE, SET_METHOD, SET_MENU, SET_DISCOUNTED_PRICE, APPLY_COUPON, SET_SHIPPING, GET_SHIPPING_INFO, INCREASE_COUNT, DECREASE_COUNT, GET_BUNDLE_DATA, GET_TRACKER_DATA, SET_CONSENT, SET_CAPTCHA_TOKEN } from 'actions/main';

const initState = {
    plan: 'all-in-bundle',
    coupon: '',
    isCouponApplied: false,
    btToken: '',
    nonce: '',
    paymentType: null,
    count: 1,
    trackerPrice: null,
    allInPrice: null,
    trackerInitPrice: 99.95,
    allInInitPrice: 359,
    isMenuShown: true,
    isShippingShown: false,
    countriesList: [],
    shippingCost: null,
    isConsentGiven: null,
};

const setShippingData = (state, { payload }) => {

    const {
        countriesList,
        shippingCost

    } = payload;

    return {
        ...state,
        countriesList: countriesList,
        shippingCost: shippingCost
    };
};

const setBundleData = (state, { payload }) => {
    return {
        ...state,
        allInPrice: payload
    };
};

const setTrackerData = (state, { payload }) => {
    return {
        ...state,
        trackerPrice: payload
    };
};

const selectPlan = (state, { payload }) => {

    if (payload === 'all-in-bundle') {
        return {
            ...state,
            count: 1,
            plan: payload
        };
    }

    return { ...state, plan: payload };
};

const addCount = (state, { payload }) => {
    const { count } = state;

    return {
        ...state,
        count: count + 1,
        ...payload
    };
};

const subtractCount = (state, { payload }) => {
    const { count } = state;

    if (count === 1) {
        return {
            ...state,
            count: 1,
            ...payload
        };
    }

    return {
        ...state,
        count: count - 1,
        ...payload
    };
};

const setBtToken = (state, { payload }) => {
    return {
        ...state,
        btToken: payload
    };
};

const setCoupon = (state, { payload }) => {
    return {
        ...state,
        coupon: payload
    };
};

const setApplied = (state, { payload }) => {
    const {isCouponApplied} = state;

    return {
        ...state,
        isCouponApplied: !isCouponApplied,
        ...payload
    };
};

const setNonce = (state, { payload }) => {

    const {type, nonce} = payload;

    return {
        ...state,
        nonce: nonce,
        paymentType: type,
    };
};

const setDiscount = (state, { payload }) => {
    const { count } = state;
    const discountPerDevice = payload / count;
    return {
        ...state,
        trackerPrice: discountPerDevice,
    };
};

const setMenu = (state, {payload}) => {

    return {
        ...state,
        isMenuShown: payload,
        ...payload,
    };
};

const setShipping = (state, {payload}) => {

    return {
        ...state,
        isShippingShown: payload,
    };
};


const setConsentData = (state, { payload }) => {
    
    return {
        ...state,
        isConsentGiven: payload,
    };
};

const setCaptchaToken = (state, {payload}) => {

    const {type, token} = payload;

    if (type === 'braintree') {
        return {
            ...state,
            captchaTokenBrainTree: token,
        };
    }

    if (type === 'signup') {
        return {
            ...state,
            captchaTokenSignup: token,
        };
    }
};




const handlers = {
    [SELECT_PLAN]: selectPlan,
    [GET_BT_TOKEN.SUCCESS]: setBtToken,
    [SET_COUPON]: setCoupon,
    [SET_NONCE]: setNonce,
    [SET_MENU]: setMenu,
    [SET_DISCOUNTED_PRICE]: setDiscount,
    [APPLY_COUPON.SUCCESS]: setApplied,
    [SET_SHIPPING]: setShipping,
    [INCREASE_COUNT]: addCount,
    [DECREASE_COUNT]: subtractCount,
    [GET_SHIPPING_INFO.SUCCESS]: setShippingData,
    [GET_BUNDLE_DATA.SUCCESS]: setBundleData,
    [GET_TRACKER_DATA.SUCCESS]: setTrackerData,
    [SET_CONSENT]: setConsentData,
    [SET_CAPTCHA_TOKEN]: setCaptchaToken,
};

export default createReducer(initState, handlers);

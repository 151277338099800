// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".What-style__What--1hR4k {\n  padding-top: 24px; }\n  @media screen and (min-width: 1440px) {\n    .What-style__What--1hR4k {\n      padding: 70px; } }\n  .What-style__What__title--1oy3f {\n    display: block;\n    font-family: Avenir-Black;\n    font-size: 18px;\n    line-height: 22px;\n    color: #323232;\n    margin-top: 24px;\n    margin-bottom: 37px; }\n  .What-style__What__list--eLqAv {\n    list-style: none;\n    padding: 0; }\n  .What-style__What__item--1Bq_j {\n    font-family: Avenir-light;\n    font-size: 14px;\n    line-height: 18px;\n    color: #323232;\n    padding-bottom: 38px; }\n    @media screen and (min-width: 768px) {\n      .What-style__What__item--1Bq_j {\n        font-size: 16px;\n        line-height: 22px; } }\n    @media screen and (min-width: 1440px) {\n      .What-style__What__item--1Bq_j {\n        padding-bottom: 25px; } }\n    .What-style__What__item--1Bq_j::before {\n      content: '\\2713\\0020';\n      color: #00BFA5;\n      font-size: 16px;\n      margin-right: 7px; }\n", ""]);
// Exports
exports.locals = {
	"What": "What-style__What--1hR4k",
	"What__title": "What-style__What__title--1oy3f",
	"What__list": "What-style__What__list--eLqAv",
	"What__item": "What-style__What__item--1Bq_j"
};
module.exports = exports;

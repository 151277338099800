import React from 'react';
import style from './Coupon.style';
import { connect } from 'react-redux';
import { onChange } from 'helper';
import { setCouponAction, applyCouponAction } from 'actions/main';
import classNames from 'classnames/bind';



const Coupon = ({ plan, setCouponAction, applyCouponAction, isCouponApplied, coupon }) => {

    const styleCoupon = isCouponApplied ? classNames(style.coupon__input, style.coupon__input_applied) : style.coupon__input;

    const isEmpty = !!coupon.trim().length;

    return (
        <div className={style.coupon}>
            <input disabled={isCouponApplied} type="text" onChange={onChange(setCouponAction)} className={styleCoupon} placeholder="Coupon Code (optional)" />
            <button disabled={!isEmpty || isCouponApplied} className={style.coupon__btn} onClick={applyCouponAction}>{isCouponApplied ? 'Applied' : 'Apply'}</button>
        </div>
    );
};

const mapStateToProps = ({ main }) => {
    return {
        ...main,
    };
};

const mapDisparchToProps = {
    setCouponAction,
    applyCouponAction
};

export default connect(mapStateToProps, mapDisparchToProps)(Coupon);

import React from 'react';
import { Provider } from 'react-redux';
import Router from './Router';
import store from './redux/redux';
import './App.global';
import useLoadScripts from './scripts/ScriptProvider';
import reCaptcha from './scripts/reCaptcha';

const App = (props) => {


    useLoadScripts({ src: reCaptcha});

    return (
        <Provider store={store}>
            <Router />
        </Provider>
    );
};


export default App;
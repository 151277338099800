import React from 'react';
import style from './Footer.style.scss';
import classNames from 'classnames/bind';
import { FB, Insta, Twitter, Linkedin, Apple, GGL } from './icons';




const LINK = [
    {
        title: 'About',
        route: [
            {url: 'https://geozilla.com/about/', name: 'About GeoZilla'},
            {url: 'https://geozilla.com/about/', name: 'Team'},
            {url: 'https://geozilla.com/blog/', name: 'Blog'},
            {url: 'https://geozilla.com/contact/', name: 'Press'},
        ]
    },
    {
        title: 'Offers',
        route: [
            {url: 'https://gps-tracker.geozilla.com', name: 'GeoZilla Tracker'},
            {url: 'https://geozilla.com/guideline/', name: 'Activate Tracker'},
            {url: 'https://premium.geozilla.com/?yearly', name: 'Premium Plan'},
        ]
    },
    {
        title: 'Technology',
        route: [
            {url: 'https://findmy.geozilla.com/', name: 'Location Safety'},
            {url: 'https://geozilla.com/driving/', name: 'Driver Protection'},
        ]
    },
    {
        title: 'Help',
        route: [
            {url: 'https://geozillahelp.zendesk.com', name: 'FAQ'},
            {url: 'https://geozilla.com/privacy-policy/', name: 'Privacy'},
            {url: 'https://geozilla.com/terms-of-use/', name: 'Terms'},
            {url: 'https://geozilla.com/contact/', name: 'Contact Us'},
            {url: 'https://jobs.dou.ua/companies/geozilla/vacancies', name: 'Careers'},
        ]
    },
    {
        title: 'Social',
        route: [
            {url: 'https://www.instagram.com/geozilla_family', name: 'Instagram'},
            {url: 'https://www.facebook.com/GeoZillaApp', name: 'Facebook'},
            {url: 'https://twitter.com/GeoZillaApp', name: 'Twitter'},
            {url: 'https://www.linkedin.com/company/geozilla', name: 'LinkedIn'},
        ]
    },
];

const renderLink = ({name, url}) => {
    return (
        <li key={name} className={style.Footer__item}>
            <a href={url} className={style.Footer__link}>
                {name}
            </a>
        </li>
    );
};

const renderLinkList = ({title, route}) => (
    <ul className={style.Footer__list} key={title}>
        <li>
            <h3 className={style.Footer__title}>{title}</h3>
        </li>
        {
            route.map(renderLink)
        }
    </ul>
);

const Footer = () => (
    <div className={style.Footer__wr}>
        <div className='container'>
            <div className={style.Footer__titleBlock}>
                <p className={style.Footer__head}>Download GeoZilla</p>
                <a href='https://go.onelink.me/app/geozillaapp' className={style.Footer__storeBtn}>
                    <Apple/>
                </a>
                <a href='https://go.onelink.me/app/geozillaandroid' className={classNames(style.Footer__storeBt, style.Footer__gglBtn)}>
                    <GGL />
                </a>
            </div>
            <nav className={style.Footer__nav}>
                {LINK.map(renderLinkList)}
            </nav>
            <div className={style.Footer__soc}>
                <p>Copyright &copy; 2023 GeoZilla. All rights reserved.</p>
                <div className={style.Footer__termsLinks}>
                    <a href={'https://geozilla.com/privacy-policy/'}className={style.Footer__terms}>Privacy</a>
                    <a href={'https://geozilla.com/terms-of-use/'} className={style.Footer__terms}>Terms</a>
                </div>
                <div className={style.Footer__icons}>
                    <a href='https://www.facebook.com/GeoZillaApp' className={style.Footer__ico}>
                        <FB/>
                    </a>
                    <a href='https://twitter.com/GeoZillaApp' className={style.Footer__ico}>
                        <Twitter/>
                    </a>
                    <a href='https://www.linkedin.com/company/geozilla' className={style.Footer__ico}>
                        <Linkedin/>
                    </a>
                    <a href='https://www.instagram.com/geozilla_family' className={style.Footer__ico}>
                        <Insta/>
                    </a>
                </div>
            </div>
        </div>
    </div>);


export default Footer;

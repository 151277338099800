import {createReducer} from '../common';
import {GET_COUNTRY_DATA} from 'actions/seo';
import {showSupport} from 'helper';


const initState = {
    country: null,
    showSupportWidget: null
};

const setCountry = (state, {payload}) => {

    if (showSupport(payload)) {
        return {
            ...state,
            country: payload,
            showSupportWidget: false,
        };
    }

    return {
        ...state,
        country: payload,
        showSupportWidget: true,
    };
};

const handlers = {
    [GET_COUNTRY_DATA.SUCCESS]: setCountry,
    [GET_COUNTRY_DATA.FAILURE]: setCountry,
};

export default createReducer(initState, handlers);
import { createReducer } from '../common';
import {SET_STEP_STATE} from '../actions/steps';
const initState = {
    1: {
        label: 'Home',
        active: false,
        hidden: false
    },
    2: {
        label: 'Payment Completion',
        active: false,
        hidden: false
    },
    3: {
        label: 'Shipping Details',
        active: false,
        hidden: false
    },
};

const setStepState = (state, { payload }) => {
    const { step, active, hidden } = payload;
    return {
        ...state,
        [step]: {
            ...state[step],
            active,
            hidden
        },
    };
};


const handlers = {
    [SET_STEP_STATE]: setStepState,
};


export default createReducer(initState, handlers);

import user from './user';
import main from './main';
import checkout from './checkout';
import seo from './seo';

export default [
    ...user,
    ...main,
    ...checkout,
    ...seo,
];
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Coupon-style__coupon--3Poq4 {\n  position: relative;\n  width: 100%; }\n  @media screen and (min-width: 1200px) {\n    .Coupon-style__coupon--3Poq4 {\n      grid-column: 2;\n      grid-row: 5; } }\n  .Coupon-style__coupon__input--2kYhI {\n    width: 100%;\n    font-family: Avenir-Light;\n    height: 42px;\n    background: #FFFFFF;\n    border: 1px solid #D8D8D8;\n    box-sizing: border-box;\n    border-radius: 4px;\n    font-size: 16px;\n    line-height: 22px;\n    color: #000000;\n    display: block;\n    padding: 5px 5px 5px 10px;\n    margin: 16px 0; }\n    @media (max-width: 1024px) {\n      .Coupon-style__coupon__input--2kYhI {\n        font-size: 13px;\n        line-height: 15px; } }\n    @media (max-width: 374px) {\n      .Coupon-style__coupon__input--2kYhI {\n        font-size: 10px; } }\n    .Coupon-style__coupon__input--2kYhI:focus {\n      outline: none;\n      border-color: #000000; }\n    .Coupon-style__coupon__input--2kYhI::placeholder {\n      font-size: 14px;\n      line-height: 20px;\n      color: #9C9C9C; }\n    .Coupon-style__coupon__input_applied--3u-k9 {\n      opacity: 0.6; }\n    .Coupon-style__coupon__input--err--3jI8A {\n      border: 1.2px solid #F3364E; }\n  .Coupon-style__coupon__btn--1jwJK {\n    border: none;\n    margin: 2px;\n    font-size: 14px;\n    display: initial;\n    position: absolute;\n    bottom: 8px;\n    right: 10px;\n    color: #23CBA7;\n    background-color: transparent;\n    font-family: Avenir-Medium; }\n    .Coupon-style__coupon__btn--1jwJK:hover {\n      color: #408878; }\n    .Coupon-style__coupon__btn--1jwJK:focus {\n      outline: none; }\n    .Coupon-style__coupon__btn--1jwJK:disabled {\n      color: #E0E0E0; }\n", ""]);
// Exports
exports.locals = {
	"coupon": "Coupon-style__coupon--3Poq4",
	"coupon__input": "Coupon-style__coupon__input--2kYhI",
	"coupon__input_applied": "Coupon-style__coupon__input_applied--3u-k9",
	"coupon__input--err": "Coupon-style__coupon__input--err--3jI8A",
	"coupon__btn": "Coupon-style__coupon__btn--1jwJK"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BuyBlock-style__BuyBlock--3jgZN {\n  margin-bottom: 30px; }\n  @media screen and (min-width: 768px) {\n    .BuyBlock-style__BuyBlock--3jgZN {\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: space-between; } }\n  @media screen and (min-width: 1200px) {\n    .BuyBlock-style__BuyBlock--3jgZN {\n      grid-column: 2;\n      grid-row: 6; } }\n  .BuyBlock-style__BuyBlock__text--dM4wJ {\n    font-family: Avenir-Light;\n    font-size: 12px;\n    line-height: 18px;\n    letter-spacing: -0.41px;\n    color: #9C9C9C;\n    white-space: pre-line;\n    position: relative; }\n    .BuyBlock-style__BuyBlock__text--dM4wJ::after {\n      content: \"\";\n      width: 340px;\n      height: 1px;\n      background-color: #F2F2F2;\n      position: absolute;\n      bottom: -8px;\n      left: 0; }\n      @media screen and (min-width: 768px) {\n        .BuyBlock-style__BuyBlock__text--dM4wJ::after {\n          width: 100%;\n          bottom: 24px; } }\n    @media screen and (min-width: 768px) {\n      .BuyBlock-style__BuyBlock__text--dM4wJ {\n        order: -1;\n        flex-grow: 1;\n        margin-bottom: 0;\n        padding-bottom: 45px; } }\n  .BuyBlock-style__BuyBlock__list--OPotW {\n    padding: 0;\n    width: 70%;\n    max-width: 300px;\n    margin: 0 auto 10px;\n    list-style: none;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between; }\n    @media screen and (min-width: 768px) {\n      .BuyBlock-style__BuyBlock__list--OPotW {\n        width: 40%;\n        order: 1;\n        margin: 13px 0 0 0; } }\n  .BuyBlock-style__BuyBlock__item--hqN-E img {\n    width: 30px; }\n", ""]);
// Exports
exports.locals = {
	"BuyBlock": "BuyBlock-style__BuyBlock--3jgZN",
	"BuyBlock__text": "BuyBlock-style__BuyBlock__text--dM4wJ",
	"BuyBlock__list": "BuyBlock-style__BuyBlock__list--OPotW",
	"BuyBlock__item": "BuyBlock-style__BuyBlock__item--hqN-E"
};
module.exports = exports;

import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {selectPlanActions} from 'actions/main';
import useLoadScripts from '../../scripts/ScriptProvider';
import Privy from '../../scripts/Privy';
import Help from '../../scripts/Help';
import Offer from '../../components/Offer/Offer';
import {selectRoute, loadConditionalScript} from '../../helper';
import About from '../../components/About/About';
import WhatsInBox from '../../components/WhatsInBox/WhatsInBox';
import Timer from "../../components/Timer/Timer";
import What from "../../components/What/What";


const Main = props => {
    const {
        plan,
        history: {
            push
        },
        showSupportWidget
    } = props;

    const onClick = () => selectRoute(push, plan);


    useLoadScripts({script: Privy, check: true});

    useEffect(() => {
        if (showSupportWidget) {
            loadConditionalScript(null, Help);
        }
    }, [showSupportWidget])


    return (
        <>
            <div className='wrapper'>
                <Timer/>
                <Offer onClick={onClick}/>
            </div>
            <About/>
            <What onClick={onClick}/>
            <WhatsInBox onClick={onClick}/>
        </>

    );

};

const mapStateToProps = ({main, seo}) => {
    return {
        ...main,
        ...seo
    };
};

const mapDispatchToProps = {
    selectPlanActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
import axios from 'axios';
import {requestAuth} from 'helper';
import {toParam} from 'helper';

const {API_URL_ONE} = process.env;

const loginSoc = async (params) => {
    const url = API_URL_ONE + '/authorize_social_via_access_token' + toParam(params);
    const {data: {data}} = await axios.get(url, params);
    return {
        ...data,
    };
};

const loginEmail = async (params) => {
    const url = API_URL_ONE + '/login';
    const {data: {data}} = await axios.post(url, params);
    return {
        ...data,
    };
};

const registerForEmail = async ({captchaTokenSignup, params}) => {
    const url = API_URL_ONE + '/signup?g-recaptcha-response=' + captchaTokenSignup;
    const {data: {data}} = await axios.post(url, params);
    return {
        ...data,
    };
};

const getBtToken = async ({captchaTokenBrainTree}) => {
    const url = API_URL_ONE + '/tracker-purchase/get-client-token?g-recaptcha-response=' + captchaTokenBrainTree;

    const {data: {data: {client_token}}} = await axios.get(url);
    return client_token;
};

const getUserInfo = async (token) => {
    const url = API_URL_ONE + '/users/me';
    const config = requestAuth(token);
    const {data: {data: {entity}}} = await axios.get(url, config);
    return entity;
};

const logoutUser = async (token) => {
    const url = API_URL_ONE + '/users/me/logout';
    const config = requestAuth(token);
    const {data: {data}} = await axios.get(url, config);
    return data;
};

const resetPassword = async (params) => {
    const url = API_URL_ONE + '/restore_password';
    const {data: {data}} = await axios.post(url, params);
    return {
        ...data,
    };
};

const buyBundle = async (params) => {
    const url = API_URL_ONE + '/users/me/braintree/purchase-ai-product';
    const {params: postData, headers} = requestAuth(params);
    const {data: {data}} = await axios.post(url, postData, {headers});
    return {
        ...data,
    };
};

const buyTracker = async (params) => {
    const url = API_URL_ONE + '/tracker-purchase/charge';
    const {data: {data}} = await axios.post(url, params);
    return {
        ...data,
    };
};

const calculateTracker = async (params) => {
    const url = API_URL_ONE + '/tracker-purchase/calculate-order';
    const {data: {data}} = await axios.post(url, params);
    return {
        ...data,
    };
};

const getShippingInfo = async () => {
    const url = API_URL_ONE + '/cart/shipping-info';
    const {data: {data}} = await axios.get(url);
    return data;
};

const getProductInfo = async (params) => {
    const url = API_URL_ONE + '/cart/product-info' + toParam(params);
    const {data: {data}} = await axios.get(url);
    return data;
};

const getCountryInfo = async () => {

    const url = API_URL_ONE + '/cf-country';

    const {data} = await axios.get(url);
    return data;
}

export default {
    loginSoc,
    loginEmail,
    registerForEmail,
    getBtToken,
    getUserInfo,
    logoutUser,
    resetPassword,
    buyBundle,
    buyTracker,
    calculateTracker,
    getShippingInfo,
    getProductInfo,
    getCountryInfo
};
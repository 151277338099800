import React from 'react';
import style from './Header.style';
import Steps from '../Steps/Steps';
import { withRouter } from 'react-router';




const Header = ({ location: { pathname } }) => (

    <div className={pathname === '/checkout' ? style.header_withNav : style.header}>
        <a href="https://geozilla.com">
            <svg width="63" height="80" viewBox="0 0 63 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.7195 79.9039C56.595 54.3235 59.7927 45.614 59.7927 45.614C61.845 41.4131 63 36.6739 63 31.6751C63 14.1793 48.9109 0 31.5477 0H31.4618C14.0795 0 0 14.1889 0 31.6751C0 36.6835 1.14545 41.4131 3.20727 45.614C3.20727 45.614 6.405 54.3235 31.29 79.9039C31.3473 79.9615 31.4236 80 31.5095 80C31.5764 80 31.6623 79.9615 31.7195 79.9039Z" fill="#00BFA5"/>
                <path d="M49.5409 29.5411H38.5636C36.5877 29.5411 34.9841 31.1465 34.9841 33.146C34.9841 35.1359 36.5782 36.7509 38.5636 36.7509H42.4296C42.9259 36.7509 43.2886 36.9143 43.5368 37.2412C43.7755 37.5584 43.785 37.9525 43.5655 38.414C43.3077 39.0965 42.9164 39.7694 42.4296 40.4135C41.5609 41.5574 40.5873 42.5091 39.5373 43.2782C38.4396 44.0761 37.2273 44.6913 35.9005 45.1143C34.5736 45.5757 33.1896 45.7968 31.7482 45.7968C29.8486 45.7968 28.0923 45.3931 26.4696 44.5952C24.7705 43.8357 23.3291 42.8071 22.155 41.5094C20.9427 40.2116 19.9596 38.7024 19.2055 36.9912C18.4896 35.3186 18.1173 33.5017 18.1173 31.5598C18.1173 29.6468 18.4705 27.8396 19.2055 26.1285C19.9596 24.4173 20.9427 22.9081 22.155 21.6103C23.2909 20.351 24.7323 19.3032 26.4696 18.4668C28.1686 17.7074 29.9346 17.3229 31.7482 17.3229C33.9818 17.3229 36.1009 17.9381 38.1055 19.159C38.7641 19.5435 39.795 20.3606 39.9668 20.5048C39.9859 20.524 40.005 20.5336 40.0146 20.5529L40.0241 20.5625C41.5514 22.1006 44.0236 22.1967 45.5891 20.7259C47.2309 19.1782 47.2595 16.5634 45.675 14.9773L45.6082 14.9196C44.5295 14.0064 43.3555 13.1893 42.0668 12.4875C38.9646 10.7379 35.5186 9.85352 31.7386 9.85352C28.7891 9.85352 26.0018 10.4303 23.3864 11.5646C20.8091 12.7086 18.5182 14.2563 16.5136 16.1885C15.5305 17.1787 14.6427 18.2457 13.8696 19.3897C13.0964 20.5336 12.4186 21.7737 11.8555 23.1099C10.7196 25.8208 10.1564 28.6375 10.1564 31.5791C10.1564 34.5495 10.7291 37.3565 11.8555 39.9905C12.9532 42.5476 14.5091 44.8355 16.5041 46.8446C18.4705 48.8249 20.7614 50.3919 23.3768 51.5262C25.9923 52.6702 28.77 53.2373 31.7291 53.2373C34.4209 53.2373 36.9886 52.7759 39.4514 51.8627C41.9045 50.9494 44.0618 49.6709 45.9232 48.0367C47.7845 46.4409 49.3118 44.5087 50.5145 42.2592C51.6218 40.1924 52.3186 37.8852 52.5764 35.3378L52.6336 34.8475C52.6623 34.6553 52.7673 33.7228 52.7864 33.0883V32.8288C52.815 30.9734 51.345 29.5123 49.5409 29.5411Z" fill="white"/>
            </svg>
        </a>
        {pathname === '/checkout' &&  <Steps />}
    </div>
);

export default withRouter(Header);
import { createAsyncAction, createAction } from '../common';

export const BUY_PLAN = createAsyncAction('buyPlan');
export const buyPlanAction = (payload) => {
    return {
        type: BUY_PLAN.REQUEST,
        payload,
    };
};

export const SET_TOTAL_TRACKER = createAsyncAction('setTotalTracker');
export const setTotalTrackerAction = (payload) => {
    return {
        type: SET_TOTAL_TRACKER.REQUEST,
        payload,
    };
};

export const SET_ORDER_ID = 'setOrderId';
export const setOrderIdAction = createAction(SET_ORDER_ID);

export const SET_STATIC_TOTAL = 'setTotalStatic';
export const setTotalStaticAction = createAction(SET_STATIC_TOTAL);

export const SET_SUBSC_ID = 'setSubscId';
export const setSubscIdAction = createAction(SET_SUBSC_ID);

export const SET_SHIPPING_COST = createAsyncAction('setShippingCost');
export const setShippingCostAction = (payload) => {
    return {
        type: SET_SHIPPING_COST.REQUEST,
        payload,
    };
};

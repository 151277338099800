import { createAsyncAction } from '../common';

export const LOGIN_USER = createAsyncAction('loginUser');
export const loginUserSocAction = (payload) => {
    return {
        type: LOGIN_USER.REQUEST,
        payload,
    };
};

export const SET_PARAMS_USEER = 'setParamsUser';
export const setParamsAction = (payload) => {
    return {
        type: SET_PARAMS_USEER,
        payload
    };
};

export const SET_EMAIL_USER = 'setEmailUser';
export const setEmailAction = (payload) => {
    return {
        type: SET_EMAIL_USER,
        payload,
    };
};

export const SET_PASSWORD_USER = 'setPAsswordUser';
export const setPasswordAction = (payload) => {
    return {
        type: SET_PASSWORD_USER,
        payload,
    };
};


export const LOGIN_USER_EMAIL = createAsyncAction('loginUserEmail');
export const loginUserEmailAction = (payload) => {
    return {
        type: LOGIN_USER_EMAIL.REQUEST,
        payload,
    };
};
export const REGISTER_USER_EMAIL = createAsyncAction('registerUserEmail');
export const registerUserEmailAction = (payload) => {
    return {
        type: REGISTER_USER_EMAIL.REQUEST,
        payload,
    };
};

export const SHOW_PASSWORD = 'showPassword';
export const showPasswordAction = (payload) => {
    return {
        type: SHOW_PASSWORD,
        payload,
    };
};

export const GET_USER_INFO = createAsyncAction('getUserInfo');
export const getUserInfoAction = (payload) => {
    return {
        type: GET_USER_INFO.REQUEST,
        payload,
    };
};

export const LOGOUT_USER = createAsyncAction('logoutUser');
export const logoutUserAction = (payload) => {
    return {
        type: LOGOUT_USER.REQUEST,
        payload,
    };
};

export const RESET_PASSWORD = createAsyncAction('resetPassword');
export const resetPasswordAction = (payload) => {
    return {
        type: RESET_PASSWORD.REQUEST,
        payload,
    };
};

export const SET_APPSFLYER_ID = 'setAppsflyerId';
export const setAppsflyerIdAction = (payload) => {
    return {
        type: SET_APPSFLYER_ID,
        payload,
    };
};
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".About-style__About--12BMj {\n  margin-top: 28px;\n  background: rgba(248, 248, 248, 0.68);\n  padding: 16px 0 20px; }\n  @media screen and (min-width: 768px) {\n    .About-style__About__how_wrapper--c_xXT {\n      padding-top: 40px;\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between; } }\n  .About-style__About--12BMj hr {\n    border-top: 1px solid #f2f2f2; }\n    @media screen and (min-width: 1040px) {\n      .About-style__About--12BMj hr {\n        width: 684px;\n        display: inline-block; } }\n  .About-style__About__img--2UIm6 {\n    display: block;\n    width: 100%;\n    max-width: 400px;\n    margin: 16px auto;\n    cursor: pointer; }\n    .About-style__About__img--2UIm6:hover {\n      opacity: 0.8; }\n    @media screen and (min-width: 768px) {\n      .About-style__About__img--2UIm6 {\n        margin: 0; } }\n  @media screen and (min-width: 768px) {\n    .About-style__About__text--1sXcD {\n      width: 50%;\n      margin-right: 16px;\n      order: -1; } }\n  .About-style__About__title--30waV {\n    font-family: Avenir-Medium;\n    margin-bottom: 5px; }\n  .About-style__About__spec--3oTs3 p {\n    font-family: Avenir-Light;\n    margin-bottom: 20px; }\n  .About-style__About__spec--3oTs3 span {\n    font-family: Avenir-Medium; }\n  .About-style__About__widget--2uWYf {\n    width: 300px;\n    max-width: 800px;\n    padding-top: 50%;\n    position: relative;\n    z-index: 100;\n    box-sizing: border-box; }\n    @media screen and (max-width: 374px) {\n      .About-style__About__widget--2uWYf {\n        width: 280px; } }\n    @media screen and (min-width: 768px) {\n      .About-style__About__widget--2uWYf {\n        width: 450px; } }\n    @media screen and (min-width: 1024px) {\n      .About-style__About__widget--2uWYf {\n        width: 650px; } }\n    .About-style__About__widget--2uWYf iframe {\n      position: absolute;\n      top: 0;\n      left: 0; }\n", ""]);
// Exports
exports.locals = {
	"About": "About-style__About--12BMj",
	"About__how_wrapper": "About-style__About__how_wrapper--c_xXT",
	"About__img": "About-style__About__img--2UIm6",
	"About__text": "About-style__About__text--1sXcD",
	"About__title": "About-style__About__title--30waV",
	"About__spec": "About-style__About__spec--3oTs3",
	"About__widget": "About-style__About__widget--2uWYf"
};
module.exports = exports;

import React from 'react';
import style from './About.style';
import { connect } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import thumb from 'assets/main/default/unboxing-thum.png';
import Modal from '../../components/UI/ModalPopup/ModalPopup';
import VideoModal from './VideoModal';
import {showModalAction} from 'actions/popup';




const About = ({showModalAction}) => {


    return (

        <section className={style.About}>
            <div className='container'>
                <Tabs defaultActiveKey="how">
                    <Tab eventKey="how" title="How it works" className={style.About__how}>
                        <hr/>
                        <div className={style.About__how_wrapper}>
                            <div>
                                <img src={thumb} className={style.About__img} alt='Show video' onClick={showModalAction} />
                                <Modal>
                                    <VideoModal/>
                                </Modal>
                            </div>
                            <div className={style.About__text}>
                                <p>
                                        The GeoZilla GPS Tracker device uses GPS (Global Positioning System)
                                        and Cell ID technology to accurately locate the device.
                                </p>
                                <p>
                                        When GeoZilla Tracker receives the location updates it uses wireless GSM network
                                        to transmit those positions to the GeoZilla app where you and your family can view the device location.
                                </p>
                            </div>
                        </div>
                    </Tab>
                
                    <Tab eventKey="faq" title="FAQ" className={style.About__faq}>
                        <hr/>
                        <div className={style.About__title}>How do I use the device?</div>
                        <p>You download the GeoZilla iOS or Android app and pair your device with the app.</p>

                        <div className={style.About__title}>Can I share the device with family?</div>
                        <p>
                                Yes, once paired, you can share access to the device's location with other family
                                members, through the Geozilla app.
                        </p>

                        <div className={style.About__title}>Do I need to get a service plan?</div>
                        <p>
                            Yes, service plans are available in the app when you first pair your device. Prices start at $4.42 per month for a long term plan of 3 years. When you purchase any service plan, you get 30 additional days of service for free. If you choose our 12 Months All-Inclusive Package, you will have 12 months of worldwide coverage included.
                        </p>
                    </Tab>
                

                    <Tab eventKey="specs" title="Specification" className={style.About__spec}>
                        <hr/>
                        <p>
                            <span>GSM Platform:</span> MTK6276
                        </p>
                        <p>
                            <span>GPS Platform:</span> MTK3337
                        </p>
                        <p>
                            <span>Bands:</span> GSM GSM 850/ GSM900/ DCS1800/ PCS1900 WCDMA 850/900/1900/2100
                        </p>
                        <p>
                            <span>Sim Card:</span> eSim Included
                        </p>
                        <p>
                            <span>Interface:</span> iOS/Android
                        </p>
                        <p>
                            <span>Dimensions:</span> 47 mm x 41 mm x 17 mm
                        </p>
                        <p>
                            <span>Weight:</span> 30 grams
                        </p>
                        <p>
                            <span>Active battery life:</span> 48-72 hours
                        </p>
                        <p>
                            <span>Stand-by time:</span> More than 144 hours [varies by network]
                        </p>
                        <p>
                            <span>Battery Type Rechargeable:</span> Li-ion 520mAh
                        </p>
                        <p>
                            <span>Functioning temperatures:</span> -20 +60C
                        </p>
                        <p>
                            <span>Wi-Fi:</span> Yes
                        </p>
                    </Tab>

                </Tabs>
            </div>
            
        </section>
   
    );
};


const mapDispatchToProps = {
    showModalAction,
};

export default connect(null, mapDispatchToProps)(About);
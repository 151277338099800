// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Card-style__card--2CQdz {\n  width: 100%;\n  max-width: 400px;\n  min-height: 172px;\n  margin-bottom: 24px;\n  padding: 15px 27px 11px;\n  box-sizing: border-box;\n  background-color: #FFFFFF;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  border: 1px solid #E0E0E0;\n  text-align: center;\n  cursor: pointer;\n  position: relative; }\n  @media screen and (min-width: 768px) {\n    .Card-style__card--2CQdz {\n      padding: 15px 7px 11px; } }\n\n.Card-style__main--y2Z3O {\n  border: 1px solid #00BFA5; }\n\n.Card-style__summary--3fhDT {\n  width: 100%;\n  max-width: 800px;\n  height: auto;\n  padding: 14px;\n  align-items: initial;\n  border-radius: 10px; }\n  @media screen and (min-width: 768px) {\n    .Card-style__summary--3fhDT {\n      padding: 30px 24px 27px; } }\n  @media screen and (min-width: 1140px) {\n    .Card-style__summary--3fhDT:nth-child(2n) {\n      order: unset; } }\n", ""]);
// Exports
exports.locals = {
	"card": "Card-style__card--2CQdz",
	"main": "Card-style__main--y2Z3O",
	"summary": "Card-style__summary--3fhDT"
};
module.exports = exports;

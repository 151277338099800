import React from 'react';
import style from './Title.style';
import Heart from 'assets/main/ico-favorite.svg';
import Stars from 'assets/main/stars.svg';
import { isMobile } from 'react-device-detect';

const privyPopup = isMobile ? 1269115 : 1267465;

const Title = () => (
    <div className={style.Title}>
        <h1 className={style.Title__title}>GeoZilla GPS Tracker</h1>
        <div className={style.Title__container}>
            <img className={style.Title__stars} src={Stars} alt='5-star reviews'/>
            <p className={style.Title__text}>Over 30,000+ 5-star reviews</p>
            <button className={style.Title__heartBtn} onClick={() => window.Privy('show', privyPopup)}>
                <img src={Heart} alt='Get a discount button'/>
            </button>
        </div>
        
    </div>
);

export default Title;
import React, { useEffect, useState } from 'react';
import style from './Timer.style';

const checkNumber = (value) => {
    value = value.toString();
    if (value.length === 1) {
        return '0' + value;
    }
    return value;
};

const timer = () => {
    const dateNow = new Date();
    let day = dateNow.getDate() % 3;
    const hour = 23 - dateNow.getHours();
    const minutes = 59 - dateNow.getMinutes();
    const second = 59 - dateNow.getSeconds();
    if (day === 1) {
        day = day + 2;
    }

    return {
        day: checkNumber(day),
        hour: checkNumber(hour),
        minutes: checkNumber(minutes),
        second: checkNumber(second),
    };
};


const Timer = (props) => {
    const [date, setDate] = useState({
        day: '00',
        hour: '00',
        minutes: '00',
        second: '00',
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const newDate = timer();
            setDate({
                ...date,
                ...newDate
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={style.timer}>
            <div className={style.timer__block}>
                <div className="p flex-fill bd-highlight">
                    <h3 className={style.timer__title}>Limited offer ends in:</h3>
                </div>
                <div className="flex-fill bd-highlight">
                    <div className={style.count_block}>
                        <div className={style.timer__num}>
                            <p className={style.timer__time}>{date.day}<span>d</span></p>
                        </div>
                        <p className={style.timer__colon}>:</p>
                        <div className={style.timer__num}>
                            <p className={style.timer__time}>{date.hour}<span>h</span></p>
                        </div>
                        <p className={style.timer__colon}>:</p>
                        <div className={style.timer__num}>
                            <p className={style.timer__time}>{date.minutes}<span>m</span></p>
                        </div>
                        <p className={style.timer__colon}>:</p>
                        <div className={style.timer__num}>
                            <p className={style.timer__time}>{date.second}<span>s</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Timer;
import { createAsyncAction, createAction } from '../common';

export const SELECT_PLAN = 'selectPlan';
export const selectPlanActions  = (payload) => {
    return {
        type: SELECT_PLAN,
        payload,
    };
};

export const SET_CAPTCHA_TOKEN = 'setCaptchaToken';
export const setCaptchaTokenAction = (payload) => {
    return {
        type: SET_CAPTCHA_TOKEN,
        payload,
    };
};

export const GET_BT_TOKEN = createAsyncAction('getBtToken');
export const getBtTokenAction = (payload) => {
    return {
        type: GET_BT_TOKEN.REQUEST,
        payload,
    };
};

export const GET_SHIPPING_INFO = createAsyncAction('getShippingInfo');
export const getShippingInfoAction = (payload) => {
    return {
        type: GET_SHIPPING_INFO.REQUEST,
        payload,
    };
};


export const GET_BUNDLE_DATA = createAsyncAction('getBundleData');
export const getBundleDataAction = (payload) => {
    return {
        type: GET_BUNDLE_DATA.REQUEST,
        payload,
    };
};

export const GET_TRACKER_DATA = createAsyncAction('getTrackerData');
export const getTrackerDataAction = (payload) => {
    return {
        type: GET_TRACKER_DATA.REQUEST,
        payload,
    };
};

export const APPLY_COUPON = createAsyncAction('applyCoupon');
export const applyCouponAction = (payload) => {
    return {
        type: APPLY_COUPON.REQUEST,
        payload,
    };
};

export const SET_DISCOUNTED_PRICE = 'setDiscountedPrice';
export const setDiscountedPriceAction = createAction(SET_DISCOUNTED_PRICE);

export const SET_SHIPPING = 'setShipping';
export const setShippingAction = createAction(SET_SHIPPING);

export const SET_COUPON = 'setCoupon';
export const setCouponAction = (payload) => {
    return {
        type: SET_COUPON,
        payload,
    };
};

export const SET_NONCE = 'setNonce';
export const setNonceAction = (payload) => {
    return {
        type: SET_NONCE,
        payload,
    };
};

export const SET_MENU = 'setMenu';
export const setMenuAction = (payload) => {
    return {
        type: SET_MENU,
        payload,
    };
};

export const INCREASE_COUNT = 'increaseCount';
export const increaseCountAction = (payload) => {
    return {
        type: INCREASE_COUNT,
        payload,
    };
};

export const DECREASE_COUNT = 'decreaseCount';
export const decreaseCountAction = (payload) => {
    return {
        type: DECREASE_COUNT,
        payload,
    };
};

export const SET_CONSENT = 'setConsent';
export const setConsentAction = (payload) => {
    return {
        type: SET_CONSENT,
        payload,
    };
};


import React from 'react';
import { connect } from 'react-redux';
import styles from './Steps.style';
import { addStyle } from '../../helper/index';
import classNames from 'classnames/bind';






const Steps = ({ steps }) => {

    const items = [];
    
    for (const item in steps) {
        items.push(item);
    }

    return (
        <ul className={classNames(styles.steps__list, styles.steps__list_small)}>
            {items.map((i => {
                if (!steps[i].hidden) {
                    return (<li key={i} className={addStyle(styles.steps__item, styles.steps__item_active, steps[i].active)}>
                        {steps[i].label}</li>);
                }
                else return null;
            }))}
        </ul>
    );
};

const mapStateToProps = ({ main, steps }) => {
    return {
        ...main,
        steps
    };
};


export default connect(mapStateToProps)(Steps);
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalPopup-style__modalPopup--2lp2A {\n  width: auto !important;\n  max-width: 95vw;\n  height: auto !important;\n  margin: 0 auto;\n  padding: 0;\n  box-sizing: border-box;\n  background: #FFFFFF;\n  border-radius: 16px !important;\n  border: none !important; }\n  @media screen and (max-width: 374px) {\n    .ModalPopup-style__modalPopup--2lp2A {\n      width: 290px; } }\n  .ModalPopup-style__modalPopup__body--3ayT7 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    text-align: center; }\n  .ModalPopup-style__modalPopup__close_btn--21Y6A {\n    width: 30px;\n    height: 40px;\n    margin-left: auto;\n    position: absolute;\n    cursor: pointer;\n    padding: 10px;\n    z-index: 100;\n    top: -30px;\n    right: -7px; }\n    .ModalPopup-style__modalPopup__close_btn--21Y6A:focus {\n      outline: none; }\n  .ModalPopup-style__modalPopup__backdrop--36gWg {\n    background-color: rgba(50, 50, 50, 0.4) !important; }\n", ""]);
// Exports
exports.locals = {
	"modalPopup": "ModalPopup-style__modalPopup--2lp2A",
	"modalPopup__body": "ModalPopup-style__modalPopup__body--3ayT7",
	"modalPopup__close_btn": "ModalPopup-style__modalPopup__close_btn--21Y6A",
	"modalPopup__backdrop": "ModalPopup-style__modalPopup__backdrop--36gWg"
};
module.exports = exports;

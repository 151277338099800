import React from 'react';
import style from './Slider.style';
import Slider from 'react-slick';
import Image from '../UI/Image';
import video from 'assets/main/unboxing.mp4';
import videoThumb from 'assets/main/default/video-thumb.png';
import defaultSize from 'assets/main/default/size.png';
import sizeThumb from 'assets/main/default/size-thumb.png';
import sizeWebp from 'assets/main/webp/size.webp';
import sizeWebp2x from 'assets/main/webp/desktop/size2x.webp';
import sizeRetina2x from 'assets/main/retina/size2x.png';
import sizeMob from 'assets/main/mobile/size.png';
import girlDefault from 'assets/main/default/girl.png';
import girlThumb from 'assets/main/default/girl-thumb.png';
import girlWebp from 'assets/main/webp/girl.webp';
import girlWebp2x from 'assets/main/webp/desktop/girl2x.webp';
import girlRetina from 'assets/main/retina/girl2x.jpg';
import girlMob from 'assets/main/mobile/girl.png';
import dogDefault from 'assets/main/default/dog.png';
import dogThumb from 'assets/main/default/dog-thumb.png';
import dogWebp from 'assets/main/webp/dog.webp';
import dogWebp2x from 'assets/main/webp/desktop/dog2x.webp';
import dogRetina from 'assets/main/retina/dog2x.jpg';
import dogMob from 'assets/main/mobile/dog.png';
import handDefault from 'assets/main/default/hand.png';
import handThumb from 'assets/main/default/hand-thumb.png';
import handWebp from 'assets/main/webp/hand.webp';
import handWebp2x from 'assets/main/webp/desktop/hand2x.webp';
import handRetina from 'assets/main/retina/hand2x.jpg';
import handMob from 'assets/main/mobile/hand.png';
import videoPoster from 'assets/main/mobile/video-poster.png';
import {isMobileOnly} from 'react-device-detect';

const showVideo = () => {
    if (isMobileOnly) {
        return (
            <video className={style.Carousel_video} preload='metadata' poster={videoPoster} playsInline muted controls>
                <source src={video} type='video/mp4' />
            </video>
        );
    }

    return (
        <div className={style.Carousel_video}>
            <video src={video} type='video/mp4' autoPlay controls muted />
        </div>
    );
};

const NextArrow = ({onClick}) => (
    <div className={style.Carousel_next} onClick={onClick}>
        <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.8829 0.940594L11.4516 9.1103C11.8127 9.4542 11.8127 10.0098 11.4516 10.3546L2.8829 18.5243C2.3617 19.0218 1.51372 19.0218 0.991595 18.5243C0.470399 18.0268 0.470399 17.2193 0.991595 16.7217L8.32167 9.732L0.991595 2.74408C0.470399 2.24564 0.470399 1.43814 0.991595 0.940594C1.51372 0.443052 2.3617 0.443052 2.8829 0.940594Z"
                  fill="#9C9C9C"/>
        </svg>
    </div>);

const PrevArrow = ({onClick}) => {
    return (
        <div className={style.Carousel_prev} onClick={onClick}>
            <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.11734 18.524L0.548613 10.3543C0.187572 10.0104 0.187572 9.45476 0.548613 9.10997L9.11734 0.940265C9.63854 0.442723 10.4865 0.442723 11.0086 0.940265C11.5298 1.43781 11.5298 2.24531 11.0086 2.74285L3.67857 9.73257L11.0086 16.7205C11.5298 17.2189 11.5298 18.0264 11.0086 18.524C10.4865 19.0215 9.63854 19.0215 9.11734 18.524Z"
                      fill="#9C9C9C"/>
            </svg>
        </div>)
};



const Carousel = () => {

    const THUMBS = [sizeThumb, videoThumb, girlThumb, dogThumb, handThumb];

    const settings = {
        customPaging: function (i) {
            return (
                <a className={style.Carousel_thumbnail}>
                    <img src={THUMBS[i]}/>
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>
    };


    return (
        <div className={style.Carousel_wrapper}>
            <Slider  {...settings} className='trackersSlider'>
                <Image mobile={sizeMob} defaultImg={defaultSize} retina={sizeRetina2x} webMobile={sizeWebp} webRetina={sizeWebp2x} className={style.Carousel_image}/>
                {showVideo()}
                <Image mobile={girlMob} defaultImg={girlDefault} retina={girlRetina} webMobile={girlWebp} webRetina={girlWebp2x} className={style.Carousel_image}/>
                <Image mobile={dogMob} defaultImg={dogDefault} retina={dogRetina} webMobile={dogWebp} webRetina={dogWebp2x} className={style.Carousel_image}/>
                <Image mobile={handMob} defaultImg={handDefault} retina={handRetina} webMobile={handWebp} webRetina={handWebp2x} className={style.Carousel_image}/>
            </Slider>
        </div>
    )
};

export default Carousel;


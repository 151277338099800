const Help = `
    (function(w,d){
        w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];
        function r(){var s=document.createElement('script');s.async=1;s.type='text/javascript';s.src='https://widget.helpcrunch.com/';(d.body||d.head).appendChild(s);}
        if(w.attachEvent){w.attachEvent('onload',r)}else{w.addEventListener('load',r,false)}
    })(window, document);

    HelpCrunch('init', 'geozilla', {
        applicationId: 1,
        applicationSecret: 'SNbcS5ULRdB8e9ZoV3lzTGpowcSRToWdqwPd8n7YpeXuDXuGirl3XB8Km3YDVMkIMxp4fDAoCJalmbEDJShyGQ=='
    });
    
    HelpCrunch('showChatWidget');
`;

export default Help;
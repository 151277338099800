import React from 'react';
import style from './Alert.style';
import classNames from 'classnames/bind';


const Alert = () => (
    <div className={classNames('alert', 'alert-warning', style.Alert)} role='alert'>
        Warning! Staging environment
    </div>
);

export default Alert;
import React from 'react';
import style from './What.style';
import Button from '../UI/Button/Button';

const What = ({plan, onClick}) => (
    <div className='container'>
        <div className={style.What}>
            <Button type='checkout' className={style.What__btn} onClick={onClick}>Buy now</Button>
            <b className={style.What__title}>What you get:</b>
            <ul className={style.What__list}>
                <li className={style.What__item}>Worldwide Coverage - GPS device tracked via GeoZilla</li>
                <li className={style.What__item}>SOS Button - With one press of the SOS button, the location can be broadcasted via app notification, email, or text</li>
                <li className={style.What__item}>Real Time Tracking - GPS and GSM provide instant, highly accurate current location</li>
                <li className={style.What__item}>Full customer service and phone support by dedicated experts</li>
        </ul>
        </div>
        
    </div>
);

export default What;
     

import {
    put,
    call,
    select,
    takeEvery
} from 'redux-saga/effects';
import {
    BUY_PLAN,
    SET_TOTAL_TRACKER,
    SET_ORDER_ID,
    SET_SHIPPING_COST,
} from 'actions/checkout';
import API from 'api';
import {
    formatShipping
} from 'helper';
import {
    SPINNER,
    MODAL
} from 'actions/popup';



function* buy() {
    const {
        main: {
            plan,
            nonce,
            count,
            coupon
        },
        form,
        user: {
            token,
            appsflyerId,
            userInfo: {
                geozilla_user_id
            }
        }
    } = yield select();
    const shipping = formatShipping(form, nonce);
    const res = {};
    yield put({
        type: SPINNER
    });


    try {
        if (plan === 'device-only') {

            const resTracker = yield call(API.buyTracker, {
                token: nonce,
                coupon,
                count,
                geozilla_user_id,
                ...shipping,
            });
            Object.assign(res, resTracker);
        }

        if (plan === 'all-in-bundle') {
            const resBundle = yield call(API.buyBundle, {
                token,
                geozilla_user_id,
                ...shipping,
                appsflyer_id: appsflyerId,
                items: [{
                    'sku': '12-months-all-inclusive-package',
                    'type': 'ai_package'
                }]
            });

            Object.assign(res, resBundle);
        }
        yield put({
            type: BUY_PLAN.SUCCESS,
            payload: res
        });

        if (plan === 'all-in-bundle') {
            yield put({
                type: SET_ORDER_ID,
                payload: res.order_id
            });
        }

        yield put({
            type: SET_ORDER_ID,
            payload: res.order.order_id
        });

    } catch (error) {
        yield put({
            type: BUY_PLAN.FAILURE,
            payload: {
                type: 'payment',
                show: true,
                body: 'Check if your funds are sufficient, limits are set right, etc.'
            }
        });
        yield put({
            type: MODAL
        });
    }
    yield put({
        type: SPINNER
    });

}

function* buyFlow() {
    yield takeEvery(BUY_PLAN.REQUEST, buy);
}

function* calculateOrder() {
    // yield put({
    //     type: SPINNER
    // });

    try {
        const {
            main: {
                paymentType,
                coupon,
                count
            },
            form
        } = yield select();

        const shipping = formatShipping(form, paymentType);

        const {
            pricing
        } = yield call(API.calculateTracker, {
            count,
            ...shipping,
            coupon
        });
        yield put({
            type: SET_TOTAL_TRACKER.SUCCESS,
            payload: pricing.total
        });
        yield put({
            type: SET_SHIPPING_COST.SUCCESS,
            payload: {
                shippingCost: pricing.shipment_total,
                salesTax: pricing.sales_taxes_total
            }
        });
    } catch (error) {
        yield put({
            type: ({
                type: SET_TOTAL_TRACKER.FAILURE,
                payload: {
                    type: 'logout',
                    show: true,
                    body: 'Something went wrong'
                }
            })
        });
    }
    // yield put({
    //     type: SPINNER
    // });

}

function* calculateFlow() {
    yield takeEvery(SET_TOTAL_TRACKER.REQUEST, calculateOrder);
}



export default [
    buyFlow,
    calculateFlow
];
import React from 'react';
import style from './Button.style';

const Button = props => {

    const cls = [
        style.button,
        style[props.type]
    ];

    return (
        <button
            onClick={props.onClick}
            className={cls.join(' ')}
            disabled={props.disabled}
            id={props.id}
        >
            {props.children}
        </button>
    );
};

export default Button;
import React, {Suspense, useEffect, useState} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Main from './pages/Main/Main';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Spinner from './components/UI/Loader/Loader';
import Alert from './components/UI/Alert/Alert';
import { setConsentAction, getBundleDataAction, getTrackerDataAction } from 'actions/main';
import { showSpinnerAction } from 'actions/popup';
import { getCountryDataAction } from 'actions/seo';
const Summary = React.lazy(() => import('./pages/Summary/Summary'));
const Success = React.lazy(() => import('./pages/Success/Success'));
const NotFound = React.lazy(() => import('./pages/NotFound/NotFound'));
const Payment = React.lazy(() => import('./pages/Payment/Payment'));
const currentAPI = process.env.API_URL_ONE;



const Router = ({ getBundleDataAction, getTrackerDataAction, showSpinnerAction, getCountryDataAction }) => {
    
    const [alert, setAlert] = useState(false);

    
    useEffect(() => {
        getBundleDataAction();
        getTrackerDataAction();
        getCountryDataAction();
    }, []);



    useEffect(() => {
        if (currentAPI.includes('stage')) {
            setAlert(true);
        }
    }, []);

    useEffect(() => {
        showSpinnerAction(true);
        setTimeout(() => showSpinnerAction(false), 1000);
    }, [])

    return (
        <BrowserRouter>
            <Header />
            {alert && <Alert/>}
            <Switch>
                <Route path='/' exact component={Main} />
                <Suspense fallback={<div style={{ 'height': '100vh' }}></div>}>
                    <Route path='/checkout' exact component={Payment} />
                    <Route path='/summary' exact component={Summary} />
                    <Route path='/success' exact component={Success} />
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <Route path='*' exact component={NotFound} />
                </Suspense>
            </Switch>
            <Footer />
            <Spinner />
        </BrowserRouter>
    );
};

const mapStateToProps = ({ main }) => {
    return {
        ...main,
    };
};

const mapDispatchToProps = {
    setConsentAction,
    getBundleDataAction,
    getTrackerDataAction,
    showSpinnerAction,
    getCountryDataAction
};


export default connect(mapStateToProps, mapDispatchToProps)(Router);
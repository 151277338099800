import React from 'react';
import bundleImg from 'assets/bundle-img.png';
import deviceImg from 'assets/main/default/device-img.png';

const bundleHeading = (<span>Premium Subscription <br/> + GPS Device + Global Service Plan</span>);

export const PLANS = {
    'all-in-bundle': {
        name: 'Yearly All-Inclusive Deal',
        heading: bundleHeading,
        icon: bundleImg,
        save: 'Save 58%',
        url: 'all-in-bundle',
        total: '$149',
        price: '$12.5/mo',
        initPrice: '$359',
        descr: 'One time payment',
        shipping: true,
        promoText: 'With a Premium App Membership and a GPS Device you can keep your loved ones and your precious belongings always on your radar.',
        promoTitle: 'With GeoZilla Premium you get',
        features: [
            'GPS tracking device for your kids, your dog, or your car',
            '12 months of worldwide device Service Plan coverage',
            'Up to 336 hours of Location History',
            'Driver Reports and Crash Detection',
            'Unlimited number of location alerts',
            'Unlimited access to Premium for your family',
        ],
        reviewAuthor: 'Nick',
        address: 'San Francisco, C.A.',
        reviewText: 'I never thought I could have my wife, kid AND my dog in one app sharing the same map! I know where to find my wife when she leaves work to pick her up, when our neighbour takes the dog for a walk and when my son is home from school.',
    },
    'device-only': {
        name: 'Device Only',
        heading: 'GeoZilla GPS Tracker',
        icon: deviceImg,
        url: 'device-only',
        price: '$79',
        initPrice: '$99.95',
        note: 'Device service plans start at $4.99 and are sold in the app ',
        shipping: true,
        promoText: 'Keep your kids, your family dog or your car safe with a GPS Tracker. When GeoZilla Tracker receives the location updates it uses a wireless 3G GSM network to transmit those positions to the GeoZilla app, so you and your family can view the device location.',
        promoTitle: 'What\'s in the box:',
        features: [
            'GeoZilla GPS Tracking Device',
            'Global Coverage eSIM',
            'Rechargeable battery',
            'USB cable',
            'Lanyard',
            'Attachment pouch - for you dog, belt, bag'
        ],
        reviewAuthor: 'Tom',
        address: 'Chicago, IL',
        reviewText: 'Last week my dog ran off when I was away. Luckily, my wife got an alert from GeoZilla and brought him back home. You won\'t believe how relieved I was!',
    }
};

export const NO_SUPPORT_CODES = [
    'FJ',
    'DZ',
    'KE',
    'SD',
    'IR',
    'GT',
    'NG',
    'PK',
    'GH',
    'LK',
    'MA',
    'BD',
    'IQ',
    'CM',
    'PH',
    'SO',
    'IN',
    'MN',
    'ET',
    'LC',
    'TZ',
    'AF',
    'SV',
    'ML',
    'MR',
    'SZ',
    'CR',
    'MM',
    'KH',
    'EG',
    'TR',
    'NP',
    'BW',
    'NA',
    'SL',
    'SB',
    'BI',
]



import React from 'react';
import style from './Guarantee.style';
import classNames from 'classnames/bind';



const Guarantee = () => (
    <ul className={style.Guarantee}>
        <li className={classNames(style.Guarantee__item, style.Guarantee__item_1)}>30 day money back guarantee</li>
        <li className={classNames(style.Guarantee__item, style.Guarantee__item_2)}>Secure checkout</li>
        <li className={classNames(style.Guarantee__item, style.Guarantee__item_3)}>We ship internationally</li>
        <li className={classNames(style.Guarantee__item, style.Guarantee__item_4)}>Global Goverage</li>
    </ul>
);

export default Guarantee;
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Counter-style__Counter--mkWGx {\n  width: 120px;\n  height: 50px;\n  background: #F8F8F8;\n  border-radius: 10px;\n  display: flex;\n  justify-content: space-around;\n  align-items: baseline;\n  padding: 7px 3px;\n  box-sizing: border-box;\n  transition: all .4s linear 0.5s; }\n  .Counter-style__Counter__btn--Fe1mP {\n    background-color: transparent;\n    border: none;\n    font-family: Avenir-Black;\n    font-size: 22px;\n    color: #BDBDBD; }\n    .Counter-style__Counter__btn--Fe1mP:focus {\n      outline: none; }\n  .Counter-style__Counter__num--I3hLo {\n    font-family: Avenir-Medium;\n    color: #323232;\n    font-size: 16px;\n    line-height: 22px; }\n", ""]);
// Exports
exports.locals = {
	"Counter": "Counter-style__Counter--mkWGx",
	"Counter__btn": "Counter-style__Counter__btn--Fe1mP",
	"Counter__num": "Counter-style__Counter__num--I3hLo"
};
module.exports = exports;


import { createAction } from '../common';

export const CHANGE_FORM = 'changeForm';
export const changeFormAction  = createAction(CHANGE_FORM);

export const CHANGE_FORM_PAYPAL = 'changeFormPaypal';
export const changeFormPaypalAction  = createAction(CHANGE_FORM_PAYPAL);

export const SET_COUNTRY_CODE = 'setCountryCode';
export const changeCountryCodeAction  = createAction(SET_COUNTRY_CODE);

export const SET_COUNTRY = 'setCountry';
export const changeCountryAction  = createAction(SET_COUNTRY);
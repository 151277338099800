import React from 'react';
import style from './Counter.style';
import {connect} from 'react-redux';
import classNames from 'classnames/bind';
import {increaseCountAction, decreaseCountAction} from 'actions/main';



const Counter = ({ count, increaseCountAction, decreaseCountAction }) => {
    return (
        <div className={style.Counter}>
            <button className={classNames(style.Counter__btn, style.Counter__btn_min)} onClick={decreaseCountAction}>–</button>
            <span className={style.Counter__num}>{count}</span>
            <button className={classNames(style.Counter__btn, style.Counter__btn_plus)} onClick={increaseCountAction}>+</button>
        </div>
    );
};

const mapStateToProps = ({ main }) => {
    return {
        ...main
    };
};

const mapDispatchToProps = {
    increaseCountAction,
    decreaseCountAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Counter);